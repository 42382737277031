$routtleBg: #1f232f;

.roulette-pro-wrapper {
  height: 220px !important;
}

.roulette-pro-regular-prize-item {
  padding-left: 5px;
  padding-right: 5px;
  height: 240px !important;
}

.roulette-pro-regular-prize-item-wrapper {
  border-color: #2f333f !important;
  border-radius: 10px;
  overflow: hidden;
  background: $routtleBg !important;
  height: 240px !important;
  background-color: #1f232f;
  border: 1px solid #2f333f !important;
  padding: 20px;
  padding-bottom: 0px;
  .roulette-pro-regular-image-wrapper {
    display: flex;
    justify-content: center;
    background-color: #252935;
    border-radius: 10px;
    overflow: hidden;
    margin: 0;
    min-height: 153px;
    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }
  .roulette-pro-regular-prize-item-text {
    background: transparent !important;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 160px;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;
    justify-content: flex-start;
    display: block;
    top: 7px;
  }
}

.roulette-pro-regular-design-top.horizontal {
  width: 2px !important;
  height: 100%;
}

.roulette-pro-regular-design-top {
  background: #fe5219 !important;
  position: absolute;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }
  &::before {
    top: 0;
    left: -7px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fe5219;
  }
  &::after {
    bottom: 0;
    left: -7px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fe5219;
  }
}
