.case-opening-case {
  .case-opening-case-img {
    &-0 {
      transition: opacity 0.3s ease;
      opacity: 1;
    }

    &-1 {
      transform: scale(1.2);
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }

  &:hover .case-opening-case-img {
    &-0 {
      opacity: 0;
    }

    &-1 {
      opacity: 1;
    }
  }
}
.case-opening-mystery-box-item {
  &-bg {
    background: linear-gradient(180deg, rgba(25, 25, 25, 0) 50%, rgba(25, 25, 25, 0.3) 75.62%, #191919 100%), linear-gradient(360deg, rgba(25, 25, 25, 0) 59.5%, rgba(25, 25, 25, 0.3) 75.7%, #191919 100%);
  }
}
.case-opening-dividing-line {
  width: 1px;
  background: linear-gradient(0, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 50%) 50%, rgba(255, 255, 255, 0) 100%);
}

.case-opening-sell-btn-green {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(30, 187, 113, 0) 100%),
    linear-gradient(0deg, rgba(30, 187, 113, 0.05), rgba(30, 187, 113, 0.05));
}
.case-opening-sell-btn-orange {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(30, 187, 113, 0) 100%),
    linear-gradient(0deg, rgba(30, 187, 113, 0.05), rgba(30, 187, 113, 0.05));
}

.case-opening-select-option-border-color {
  border-radius: 4px;
  border-width: 2px 1px 1px 1px;
  border-left-color: #2D2D2D;
  border-bottom-color: #2D2D2D;
  border-right-color: #2D2D2D;
  // border-image-source: linear-gradient(135deg, #2D2D2D 0%, #5A5A5A 48.89%, #2D2D2D 100%);
}