$glow-color: #f99433;

.glowing-light {
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 1), 0px 0px 20px $glow-color,
    0px 0px 30px $glow-color, 0px 0px 40px $glow-color, 0px 0px 55px $glow-color,
    0px 0px 75px $glow-color;

  color: #fff;
}

@keyframes glowAnimation {
  from {
    box-shadow: 0 0 5px #ff6d99, 0 0 10px #ff6d99, inset 0 0 0px #ff6d99;
  }
  to {
    box-shadow: 0 0 10px #ff6d99, 0 0 15px #ff6d99, inset 0 0 5px #ff6d99;
  }
}

.animation-marquee {
  animation: marquee var(--marquee-duration, 10s) linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--marquee-gap, 0px)));
  }
}
