.gega-notification {
  color: #A3A3A3;
  background-color: #242424;

  .ant-notification-notice-message {
    color: #E8E8E8;
  }

  .ant-notification-notice-close {
    color: #A3A3A3;
  }

  // progress bar
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    animation: 4.5s linear 0s notification-progress-bar;
    animation-fill-mode: forwards;
  }

  &.ant-notification-notice {
    &-error::after {
      background-color: #ff4d4f;
    }

    &-success::after {
      background-color: #52c41a;
    }

    &-warning::after {
      background-color: #faad14;
    }

    &-info::after {
      background-color: #1890ff;
    }
  }

  &:hover {
    &::after {
      animation-name: unset;
    }
  }

  @keyframes notification-progress-bar {
    from {
      width: 100%;
    }

    to {
      width: 0;
    }
  }
}