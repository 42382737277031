.ant-modal-close {
  display: none;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-body {
  padding-left: 24px;
  padding-right: 24px;
  background-color: #191919 !important;
  border-radius: 16px;
}

.ant-notification {
  border-radius: 20px !important;
}
