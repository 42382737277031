// .team-a-container::after {
//   content: "";
//   height: 100%;
//   left: 0;
//   opacity: 0;
//   position: absolute;
//   top: 0;
//   transition: opacity 0.5s ease-out;
//   width: 100%;
//   background: linear-gradient(
//     to right,
//     #11131e 30%,
//     var(--team-color, #f99433) 70%,
//     var(--team-color, #fe5218) 100%
//   );
//   z-index: 0;
// }

// .team-b-container::after {
//   content: "";
//   height: 100%;
//   left: 0;
//   opacity: 0;
//   position: absolute;
//   top: 0;
//   transition: opacity 0.5s ease-out;
//   width: 100%;
//   background: linear-gradient(
//     to left,
//     #11131e 30%,
//     var(--team-color, #f99433) 70%,
//     var(--team-color, #fe5218) 100%
//   );
//   z-index: 0;
// }

// .team-b-container.active::after,
// .team-a-container.active::after {
//   opacity: 0.4;
//   transition: opacity 0.5s ease-in;
// }

.team-b-container.active,
.team-a-container.active {
  background-color: #464646;
}
